// Mobile Select Menu
var pgSel = document.getElementById('pgsel');
if (pgSel) {
  pgSel.addEventListener('click', function(e) {
    var pgselect = document.getElementById('selectmenu');
    var pgUser = pgselect.options[pgselect.selectedIndex].value;
    if (pgUser) { // require a URL
      // console.log(pgUser);
      window.location.href = pgUser; // redirect
    }
    e.preventDefault();
  });
}
