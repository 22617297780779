import StickySidebar from 'sticky-sidebar';

let sidemenu = document.getElementById('sidebar');

if (sidemenu) {
  let sidebar = new StickySidebar('#sidebar', {
    containerSelector: '.contentsidebar',
    innerWrapperSelector: '.sidebarinner',
    topSpacing: 20,
    bottomSpacing: 20,
    minWidth: 800
  });
}
