// ======================================================================
// Slate Docs | hello@hashandsalt.com
// ======================================================================

// window.$ = window.jQuery = require('jquery');

// ======================================================================
// Modules
// ======================================================================

// require any further scripts here ie:
// require('npmpackagename');
// require('./mylocalfile.js');

require('./modules/prism.js');
require('./modules/selectmenu.js');
require('./modules/sticky.js');
require('./modules/tablist.js');
